import { Injectable } from '@angular/core';
import { mapFetchResultData } from '@x/common/graph';
import {
  ClonePromotionInput,
  CreatePromotionInput,
  PromotionActionTypeObject,
  PromotionFilterInput,
  PromotionRuleTypeObject,
  UpdatePromotionInput,
} from '@x/schemas/ecommerce';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  ArchiveGQL,
  CloneGQL,
  CreateGQL,
  PromotionActionDescriptionGQL,
  PromotionActionsGQL,
  PromotionDetailGQL,
  PromotionFormGQL,
  PromotionGenerateBulkCouponCodesGQL,
  PromotionItemGQL,
  PromotionItemsGQL,
  PromotionItemsQueryVariables,
  PromotionRowGQL,
  PromotionRowsGQL,
  PromotionRowsQueryVariables,
  PromotionRuleDescriptionGQL,
  PromotionRulesGQL,
  PromotionsCouponCodeSearchGQL,
  PromotionsReferrerCodeSearchGQL,
  UpdateEnabledGQL,
  UpdateGQL,
} from './gql/promotion.gql.generated';
import {
  IPromotionItemObject,
  PromotionDetailObject,
  PromotionFormObject,
  PromotionRowObject,
} from './types/promotion';

@Injectable()
export class PromotionService {
  constructor(
    private promotionRowGQL: PromotionRowGQL,
    private promotionRowsGQL: PromotionRowsGQL,
    private promotionDetailGQL: PromotionDetailGQL,
    private promotionItemGQL: PromotionItemGQL,
    private promotionItemsGQL: PromotionItemsGQL,
    private promotionFormGQL: PromotionFormGQL,
    private promotionRulesGQL: PromotionRulesGQL,
    private promotionActionsGQL: PromotionActionsGQL,
    private promotionsCouponCodeSearchGQL: PromotionsCouponCodeSearchGQL,
    private promotionsReferrerCodeSearchGQL: PromotionsReferrerCodeSearchGQL,
    private promotionRuleDescriptionGQL: PromotionRuleDescriptionGQL,
    private promotionActionDescriptionGQL: PromotionActionDescriptionGQL,
    private updateGQL: UpdateGQL,
    private updateEnabledGQL: UpdateEnabledGQL,
    private archiveGQL: ArchiveGQL,
    private cloneGQL: CloneGQL,
    private createGQL: CreateGQL,
    private generateBulkCouponCodesGQL: PromotionGenerateBulkCouponCodesGQL,
  ) {}

  fetchItem(id: number): Observable<IPromotionItemObject> {
    return this.promotionItemGQL.fetch({ id }).pipe(mapFetchResultData((r) => r.promotion));
  }

  fetchItems(
    query: PromotionItemsQueryVariables,
  ): Observable<{ items: IPromotionItemObject[]; totalItemsCount: number }> {
    return this.promotionItemsGQL.fetch(query).pipe(map((r) => r.data));
  }

  fetchRow(id: number) {
    return this.promotionRowGQL.fetch({ id }).pipe(mapFetchResultData((r) => r.promotion));
  }

  fetchRows(
    query: PromotionRowsQueryVariables,
  ): Observable<{ items: PromotionRowObject[]; totalItemsCount: number }> {
    return this.promotionRowsGQL.fetch(query).pipe(mapFetchResultData((r) => r));
  }

  fetchDetail(id: number): Observable<PromotionDetailObject> {
    return this.promotionDetailGQL.fetch({ id }).pipe(mapFetchResultData((r) => r.promotion));
  }

  fetchForm(id: number): Observable<PromotionFormObject> {
    return this.promotionFormGQL.fetch({ id }).pipe(mapFetchResultData((r) => r.promotion));
  }

  fetchRuleTypes(): Observable<PromotionRuleTypeObject[]> {
    return this.promotionRulesGQL.fetch().pipe(mapFetchResultData((r) => r.promotionRules));
  }

  fetchActionTypes(): Observable<PromotionActionTypeObject[]> {
    return this.promotionActionsGQL.fetch().pipe(mapFetchResultData((r) => r.promotionActions));
  }

  fetchCoupons(filter: PromotionFilterInput): Observable<string[]> {
    return this.promotionsCouponCodeSearchGQL.fetch({ filter }).pipe(
      map(({ data: { promotions } }) => {
        const codes = promotions
          .map((promo) => promo.couponCode)
          .filter((coupon): coupon is string => !!coupon);

        // make unique
        return [...new Set(codes)];
      }),
    );
  }

  fetchReferrerCodes(filter: PromotionFilterInput): Observable<string[]> {
    return this.promotionsReferrerCodeSearchGQL.fetch({ filter }).pipe(
      map(({ data: { promotions } }) => {
        const codes = promotions
          .map((promo) => promo.referrerCode ?? '')
          .filter((promo) => promo !== '');

        // make unique
        return [...new Set(codes)];
      }),
    );
  }

  fetchPromotionRuleDescription(id: string, configuration: any) {
    return this.promotionRuleDescriptionGQL
      .fetch({ id, configuration })
      .pipe(map((r) => r.data.promotionRuleDescription));
  }

  fetchPromotionActionDescription(id: string, configuration: any) {
    return this.promotionActionDescriptionGQL
      .fetch({ id, configuration })
      .pipe(map((r) => r.data.promotionActionDescription));
  }

  update(input: UpdatePromotionInput) {
    return this.updateGQL
      .mutate({ data: { ...input } })
      .pipe(mapFetchResultData((r) => r.updatePromotion));
  }
  updateEnabled(promotionId: number, enabled: boolean) {
    return this.updateEnabledGQL
      .mutate({ promotionId, enabled })
      .pipe(mapFetchResultData((r) => r.updatePromotionEnabled));
  }

  clone(input: ClonePromotionInput) {
    return this.cloneGQL
      .mutate({ data: { ...input } })
      .pipe(mapFetchResultData((r) => r.clonePromotion));
  }

  archive(id: number) {
    return this.archiveGQL.mutate({ id }).pipe(mapFetchResultData((r) => r.archivePromotion));
  }

  create(input: CreatePromotionInput) {
    return this.createGQL
      .mutate({ data: { ...input } })
      .pipe(mapFetchResultData((r) => r.createPromotion));
  }

  generateBulkCouponCodes(promotionId: number) {
    return this.generateBulkCouponCodesGQL
      .mutate({
        promotionId,
      })
      .pipe(mapFetchResultData((r) => r.generatePromotionBulkCouponCodes));
  }
}
