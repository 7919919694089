import { ModuleWithProviders, NgModule } from '@angular/core';
import { DataModule } from '@x/common/data';
import { LocaleModule } from '@x/common/locale';
import { ChannelAutocompleteDataSource } from './datasources/channel-autocomplete.datasource';
import { ContentAutocompleteDataSource } from './datasources/content-autocomplete.datasource';
import { ContentRefTransformer } from './datasources/content-ref.transformer';
import { CountryAutocompleteDatasource } from './datasources/country-autocomplete.datasource';
import { CountryCodeTransformer } from './datasources/country-code.transformer';
import { CurrencyAutocompleteDataSource } from './datasources/currency-autocomplete.datasource';
import { CurrencyItemIdTransformer } from './datasources/currency-item-id.transformer';
import { GeocodeAutocompleteDatasource } from './datasources/geocode-autocomplete.datasource';
import { GeoRegionAutocompleteDataSource } from './datasources/georegion-autocomplete.datasource';
import { GeoRegionIdTransformer } from './datasources/georegion-id.transformer';
import { LocaleAutocompleteDataSource } from './datasources/locale-autocomplete.datasource';
import { LocaleItemIdTransformer } from './datasources/locale-item-id.transformer';
import { MembershipAutocompleteDatasource } from './datasources/membership-autocomplete.datasource';
import { MembershipIdTransformer } from './datasources/membership-id.transformer';
import { ParcelOptionIndexDatasource } from './datasources/parcel-option-index.datasource';
import { ProductAttributeAutocompleteDataSource } from './datasources/product-attribute-autocomplete.datasource';
import { ProductAttributeIdTransformer } from './datasources/product-attribute-id.transformer';
import { ProductOptionAutocompleteDataSource } from './datasources/product-option-autocomplete.datasource';
import { ProductVariantAutocompleteDatasource } from './datasources/product-variant-autocomplete.datasource';
import { ProductVariantIdTransformer } from './datasources/product-variant-id.transformer';
import { ProvinceAutocompleteDatasource } from './datasources/province-autocomplete.datasource';
import { ProvinceCodeTransformer } from './datasources/province-code.transformer';
import { ShippingMethodIndexDatasource } from './datasources/shipping-method-index.datasource';
import { StockCategoryAutocompleteDatasource } from './datasources/stock-category-autocomplete.datasource';
import { StockCategoryIdTransformer } from './datasources/stock-category-id.transformer';
import { StockItemAutocompleteDataSource } from './datasources/stock-item-autocomplete.datasource';
import { StockItemIdTransformer } from './datasources/stock-item-id.transformer';
import { SubscriptionAutocompleteDatasource } from './datasources/subscription-autocomplete.datasource';
import { SubscriptionIdTransformer } from './datasources/subscription-id.transformer';
import { TaxonAutocompleteDataSource } from './datasources/taxon-autocomplete.datasource';
import { TaxonIdTransformer } from './datasources/taxon-id.transformer';
import { TaxonIndexDatasource } from './datasources/taxon-index.datasource';
import { TaxonProductsDatasource } from './datasources/taxon-products.datasource';
import { UserAddressIndexDatasource } from './datasources/user-address-index.datasource';
import { UserIdTransformer } from './datasources/user-id.transformer';
import { UserPaymentMethodDatasource } from './datasources/user-payment-method.datasource';
import { UserAutocompleteDatasource } from './datasources/users-autocomplete.datasource';
import { VoucherProgramAutocompleteDatasource } from './datasources/voucher-program-autocomplete.datasource';
import { WarehouseAutocompleteDataSource } from './datasources/warehouse-autocomplete.datasource';
import { WarehouseIdTransformer } from './datasources/warehouse-id.transformer';
import { DOMAIN_LANGUAGE } from './domain-language';
import { AddressItemCollectionProvider } from './providers/address.data-provider';
import { AgreementDeclarationCollectionProvider } from './providers/agreement.data-provider';
import { AuthRoleItemCollectionProvider } from './providers/auth-role.data-provider';
import { BulkOrderRowCollectionProvider } from './providers/bulk-order.data-provider';
import { ChannelItemCollectionProvider } from './providers/channel.data-provider';
import {
  CollectionPointItemCollectionProvider,
  CollectionPointRowCollectionProvider,
} from './providers/collection-point.data-provider';
import { GeoRegionItemCollectionProvider } from './providers/geo-region.data-provider';
import { GoogleProductTaxonCollectionProvider } from './providers/google-product-type.provider';
import { MediaFolderCollectionProvider } from './providers/media.data-provider';
import { MemberRowCollectionProvider } from './providers/member.data-provider';
import { NotificationLogChannelItemCollectionProvider } from './providers/notification-log-channel.data-provider';
import { NotificationLogTypeItemCollectionProvider } from './providers/notification-log-type.data-provider';
import { NotificationLogRowCollectionProvider } from './providers/notification-log.data-provider';
import {
  OrderIncidentCategoryDetailProvider,
  OrderIncidentCategoryItemCollectionProvider,
  OrderIncidentCategoryRowCollectionProvider,
} from './providers/order-incident-category.data-provider';
import {
  OrderIncidentDetailProvider,
  OrderIncidentItemCollectionProvider,
  OrderIncidentRowCollectionProvider,
} from './providers/order-incident.data-provider';
import { OrderItemRowCollectionProvider } from './providers/order-item.data-provider';
import { OrderSourceItemCollectionProvider } from './providers/order-source.data-provider';
import { OrderTagItemCollectionProvider } from './providers/order-tag.data-provider';
import { OrderTemplateItemCollectionProvider } from './providers/order-template.data-provider';
import {
  OrderItemCollectionProvider,
  OrderRowCollectionProvider,
} from './providers/order.data-provider';
import { ProductRecommendationModelItemCollectionProvider } from './providers/product-recommendation-model.data-provider';
import { ShipmentCollectionProvider } from './providers/shipment.data-provider';
import { ShippingCategoryItemCollectionProvider } from './providers/shipping-category.data-provider';
import { ShippingScheduleCollectionProvider } from './providers/shipping-schedule.data-provider';
import { StockCategoryItemCollectionProvider } from './providers/stock-category.data-provider';
import {
  StockItemDetailProvider,
  StockItemItemCollectionProvider,
  StockItemRowCollectionProvider,
} from './providers/stock-item.data-provider';
import { SubscriberTagItemCollectionProvider } from './providers/subscriber-tag.data-provider';
import { SubscriberRowCollectionProvider } from './providers/subscriber.data-provider';
import {
  SubscriptionRunDetailProvider,
  SubscriptionRunRowCollectionProvider,
} from './providers/subscription-run.data-provider';
import {
  SubscriptionDetailProvider,
  SubscriptionItemCollectionProvider,
} from './providers/subscription.data-provider';
import { TaxRateRowCollectionProvider } from './providers/tax-rate.data-provider';
import { TaxonProductRowCollectionProvider } from './providers/taxon-product.data-provider';
import { UserPaymentMethodItemCollectionProvider } from './providers/user-payment-method.data-provider';
import {
  UserItemCollectionProvider,
  UserRowCollectionProvider,
} from './providers/user.data-provider';
import { VoucherRowCollectionProvider } from './providers/voucher.data-provider';
import { WarehouseItemCollectionProvider } from './providers/warehouse.data-provider';
import { ChannelContext } from './services/channel-context.service';
import { LocaleContextService } from './services/locale-context.service';

const DATASOURCES = [
  ChannelAutocompleteDataSource,
  ContentAutocompleteDataSource,
  ContentAutocompleteDataSource,
  ContentRefTransformer,
  CountryAutocompleteDatasource,
  CountryCodeTransformer,

  CurrencyAutocompleteDataSource,
  CurrencyItemIdTransformer,
  GeocodeAutocompleteDatasource,
  GeoRegionAutocompleteDataSource,
  GeoRegionIdTransformer,
  LocaleAutocompleteDataSource,
  LocaleItemIdTransformer,
  MembershipAutocompleteDatasource,
  MembershipIdTransformer,
  SubscriptionAutocompleteDatasource,
  SubscriptionIdTransformer,

  ParcelOptionIndexDatasource,
  ProductAttributeAutocompleteDataSource,
  ProductAttributeAutocompleteDataSource,
  ProductAttributeIdTransformer,
  ProductOptionAutocompleteDataSource,
  ProductVariantAutocompleteDatasource,
  ProductVariantIdTransformer,
  ProvinceAutocompleteDatasource,
  ProvinceCodeTransformer,

  ShippingMethodIndexDatasource,
  StockCategoryAutocompleteDatasource,
  StockCategoryIdTransformer,
  StockItemAutocompleteDataSource,
  StockItemIdTransformer,

  TaxonAutocompleteDataSource,
  TaxonIdTransformer,
  TaxonIndexDatasource,
  TaxonProductsDatasource,
  UserAddressIndexDatasource,
  UserIdTransformer,

  UserPaymentMethodDatasource,

  UserAutocompleteDatasource,
  VoucherProgramAutocompleteDatasource,
  WarehouseAutocompleteDataSource,
  WarehouseIdTransformer,
  GoogleProductTaxonCollectionProvider,
  ShipmentCollectionProvider,
  StockItemDetailProvider,
  NotificationLogRowCollectionProvider,
  NotificationLogChannelItemCollectionProvider,
  NotificationLogTypeItemCollectionProvider,
  OrderSourceItemCollectionProvider,
  TaxRateRowCollectionProvider,
  SubscriberTagItemCollectionProvider,
];

export interface ChannelSharedModuleConfig {}
@NgModule({
  imports: [DataModule, LocaleModule.forChild(DOMAIN_LANGUAGE)],
  exports: [DataModule],
})
export class EcommerceDomainDataModule {
  static forRoot(
    config: ChannelSharedModuleConfig,
  ): ModuleWithProviders<EcommerceDomainDataModule> {
    return {
      ngModule: EcommerceDomainDataModule,
      providers: [
        OrderItemCollectionProvider,
        OrderRowCollectionProvider,
        OrderRowCollectionProvider,
        BulkOrderRowCollectionProvider,
        OrderTemplateItemCollectionProvider,
        ChannelItemCollectionProvider,
        GeoRegionItemCollectionProvider,
        UserItemCollectionProvider,
        WarehouseItemCollectionProvider,
        AgreementDeclarationCollectionProvider,
        AddressItemCollectionProvider,
        OrderTagItemCollectionProvider,
        UserRowCollectionProvider,
        AuthRoleItemCollectionProvider,
        CollectionPointItemCollectionProvider,
        CollectionPointRowCollectionProvider,
        StockItemItemCollectionProvider,
        SubscriptionRunDetailProvider,
        SubscriptionRunRowCollectionProvider,
        SubscriptionDetailProvider,
        SubscriptionItemCollectionProvider,
        SubscriberRowCollectionProvider,
        VoucherRowCollectionProvider,
        ShippingCategoryItemCollectionProvider,
        StockItemRowCollectionProvider,
        StockCategoryItemCollectionProvider,
        UserPaymentMethodItemCollectionProvider,
        MemberRowCollectionProvider,
        ShipmentCollectionProvider,
        ShippingScheduleCollectionProvider,
        OrderItemRowCollectionProvider,
        TaxonProductRowCollectionProvider,
        MediaFolderCollectionProvider,
        SubscriberTagItemCollectionProvider,
        ProductRecommendationModelItemCollectionProvider,

        ChannelContext,
        LocaleContextService,
        OrderIncidentCategoryItemCollectionProvider,
        OrderIncidentCategoryRowCollectionProvider,
        OrderIncidentItemCollectionProvider,
        OrderIncidentRowCollectionProvider,
        OrderIncidentDetailProvider,
        OrderIncidentCategoryDetailProvider,
        ...DATASOURCES,
      ],
    };
  }
}
