import { Injectable } from '@angular/core';
import { IDataCollection, IDataCollectionProvider, IDataQuery } from '@x/common/data';
import {
  IProductRecommendationModelItemObject,
  ProductAssociationService,
} from '@x/ecommerce/domain-client';
import { Observable, map } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ProductRecommendationModelItemCollectionProvider
  implements IDataCollectionProvider<IProductRecommendationModelItemObject>
{
  constructor(private associationService: ProductAssociationService) {}

  fetchCollection({
    searchText,
  }: Readonly<IDataQuery<any, any>>): Observable<
    IDataCollection<IProductRecommendationModelItemObject>
  > {
    return this.associationService.listRecommendationModels().pipe(
      map((a) => {
        const totalItemsCount = a.length;
        const items = a.filter((obj) =>
          searchText ? obj.label.toLowerCase().includes(searchText.toLowerCase()) : true,
        );
        return {
          totalItemsCount,
          items,
        };
      }),
    );
  }

  fetchSingle(
    id: string,
  ): IProductRecommendationModelItemObject | Observable<IProductRecommendationModelItemObject> {
    return this.associationService
      .listRecommendationModels()
      .pipe(map((a) => a.find((obj) => obj.id === id)!));
  }

  toId(model: IProductRecommendationModelItemObject): string {
    return model.id;
  }

  toString(model: IProductRecommendationModelItemObject): string {
    return model.label;
  }
}
