import * as Types from '@x/schemas/ecommerce';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type AgreementDeclarationItemsFragment = { __typename: 'AgreementDeclarationObject', id: number, agreed: boolean, agreedAt: any, name: string };

export type AgreementDeclarationItemsQueryVariables = Types.Exact<{
  page?: Types.InputMaybe<Types.PageInput>;
  sort?: Types.InputMaybe<Types.SortInput>;
  filter?: Types.InputMaybe<Types.AgreementDeclarationFilter>;
}>;


export type AgreementDeclarationItemsQuery = { __typename: 'Query', totalItemsCount: number, items: Array<{ __typename: 'AgreementDeclarationObject', id: number, agreed: boolean, agreedAt: any, name: string }> };

export const AgreementDeclarationItemsFragmentDoc = gql`
    fragment AgreementDeclarationItems on AgreementDeclarationObject {
  id
  agreed
  agreedAt
  name
}
    `;
export const AgreementDeclarationItemsDocument = gql`
    query AgreementDeclarationItems($page: PageInput, $sort: SortInput, $filter: AgreementDeclarationFilter) {
  items: agreementDeclarations(page: $page, filter: $filter, sort: $sort) {
    ...AgreementDeclarationItems
  }
  totalItemsCount: agreementDeclarationsCount(filter: $filter)
}
    ${AgreementDeclarationItemsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AgreementDeclarationItemsGQL extends Apollo.Query<AgreementDeclarationItemsQuery, AgreementDeclarationItemsQueryVariables> {
    document = AgreementDeclarationItemsDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }