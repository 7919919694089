import {
  Directive,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  Optional,
  Output,
} from '@angular/core';
import { makeId } from '../utils';
import { DragBoundaryDirective } from './drag-boundary.directive';
import { DraggableDragEvent } from './drag-events';

@Directive({
  selector: '[xDraggable]',
  host: {
    class: 'x-draggable',
  },
})
export class DraggableDirective {
  id = makeId(6);

  @Input()
  dragData: any;

  @HostBinding('attr.draggable')
  @Input()
  canDrag = true;

  @HostBinding('class.x-dragging')
  isDragging = false;

  @Output()
  startDrag = new EventEmitter<DraggableDragEvent>();

  @Output()
  endDrag = new EventEmitter<DraggableDragEvent>();

  constructor(
    @Optional()
    private dragBoundary?: DragBoundaryDirective,
  ) {}

  @HostListener('dragstart', ['$event'])
  _dragStart(event: DragEvent) {
    this.isDragging = true;
    this.dragBoundary?.setDragging(this);
    this.startDrag.emit(new DraggableDragEvent(event, this));
    event.dataTransfer?.setData('text/plain', this.id);
  }

  @HostListener('dragend', ['$event'])
  _dragEnd(event: DragEvent) {
    this.isDragging = false;
    this.dragBoundary?.resetDragging();
    this.endDrag.emit(new DraggableDragEvent(event, this));
    event.preventDefault();
  }

  @HostListener('drag', ['$event'])
  _drag(event: DragEvent) {}
}
