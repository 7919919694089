import * as Types from '@x/schemas/ecommerce';

import { gql } from 'apollo-angular';
import { ChannelItemFragmentDoc, ChannelDetailFragmentDoc } from './channel.gql.generated';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type PromotionItemFragment = { __typename: 'PromotionObject', id: number, name: string, code: string, description?: string | null | undefined, couponCode?: string | null | undefined, referrerCode?: string | null | undefined };

export type PromotionRowFragment = { __typename: 'PromotionObject', id: number, name: string, code: string, adjustmentLabel?: string | null | undefined, description?: string | null | undefined, priority: number, exclusive: boolean, enabled: boolean, usageLimit?: number | null | undefined, customerUsageLimit?: number | null | undefined, startsAt?: any | null | undefined, endsAt?: any | null | undefined, couponCode?: string | null | undefined, couponType: Types.CouponType, couponBulkQty?: number | null | undefined, couponUsageLimit?: number | null | undefined, referrerCode?: string | null | undefined, createdAt: any, updatedAt: any, archivedAt?: any | null | undefined, usage: number, channels: Array<{ __typename: 'ChannelObject', id: number, code: string, name: string }>, actions: Array<{ __typename: 'PromotionActionObject', id: number, type: string, configuration: any, typeLabel?: string | null | undefined, description?: string | null | undefined }>, rules: Array<{ __typename: 'PromotionRuleObject', id: number, type: string, configuration: any, typeLabel?: string | null | undefined, description?: string | null | undefined }> };

export type PromotionFormFragment = { __typename: 'PromotionObject', id: number, name: string, code: string, adjustmentLabel?: string | null | undefined, description?: string | null | undefined, priority: number, exclusive: boolean, enabled: boolean, usageLimit?: number | null | undefined, customerUsageLimit?: number | null | undefined, startsAt?: any | null | undefined, endsAt?: any | null | undefined, couponCode?: string | null | undefined, couponType: Types.CouponType, couponBulkQty?: number | null | undefined, couponUsageLimit?: number | null | undefined, referrerCode?: string | null | undefined, channels: Array<{ __typename: 'ChannelObject', id: number, code: string, name: string, color: string }>, actions: Array<{ __typename: 'PromotionActionObject', id: number, type: string, configuration: any }>, rules: Array<{ __typename: 'PromotionRuleObject', id: number, type: string, configuration: any }> };

export type PromotionDetailFragment = { __typename: 'PromotionObject', id: number, name: string, code: string, adjustmentLabel?: string | null | undefined, description?: string | null | undefined, priority: number, exclusive: boolean, enabled: boolean, usageLimit?: number | null | undefined, customerUsageLimit?: number | null | undefined, startsAt?: any | null | undefined, endsAt?: any | null | undefined, couponCode?: string | null | undefined, referrerCode?: string | null | undefined, createdAt: any, updatedAt: any, archivedAt?: any | null | undefined, usage: number, channels: Array<{ __typename: 'ChannelObject', id: number, code: string, name: string, color: string }>, actions: Array<{ __typename: 'PromotionActionObject', id: number, type: string, configuration: any }>, rules: Array<{ __typename: 'PromotionRuleObject', id: number, type: string, configuration: any }> };

export type PromotionRowQueryVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type PromotionRowQuery = { __typename: 'Query', promotion: { __typename: 'PromotionObject', id: number, name: string, code: string, adjustmentLabel?: string | null | undefined, description?: string | null | undefined, priority: number, exclusive: boolean, enabled: boolean, usageLimit?: number | null | undefined, customerUsageLimit?: number | null | undefined, startsAt?: any | null | undefined, endsAt?: any | null | undefined, couponCode?: string | null | undefined, couponType: Types.CouponType, couponBulkQty?: number | null | undefined, couponUsageLimit?: number | null | undefined, referrerCode?: string | null | undefined, createdAt: any, updatedAt: any, archivedAt?: any | null | undefined, usage: number, channels: Array<{ __typename: 'ChannelObject', id: number, code: string, name: string }>, actions: Array<{ __typename: 'PromotionActionObject', id: number, type: string, configuration: any, typeLabel?: string | null | undefined, description?: string | null | undefined }>, rules: Array<{ __typename: 'PromotionRuleObject', id: number, type: string, configuration: any, typeLabel?: string | null | undefined, description?: string | null | undefined }> } };

export type PromotionRowsQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.PromotionFilterInput>;
  page?: Types.InputMaybe<Types.PageInput>;
  sort?: Types.InputMaybe<Types.SortInput>;
}>;


export type PromotionRowsQuery = { __typename: 'Query', totalItemsCount: number, items: Array<{ __typename: 'PromotionObject', id: number, name: string, code: string, adjustmentLabel?: string | null | undefined, description?: string | null | undefined, priority: number, exclusive: boolean, enabled: boolean, usageLimit?: number | null | undefined, customerUsageLimit?: number | null | undefined, startsAt?: any | null | undefined, endsAt?: any | null | undefined, couponCode?: string | null | undefined, couponType: Types.CouponType, couponBulkQty?: number | null | undefined, couponUsageLimit?: number | null | undefined, referrerCode?: string | null | undefined, createdAt: any, updatedAt: any, archivedAt?: any | null | undefined, usage: number, channels: Array<{ __typename: 'ChannelObject', id: number, code: string, name: string }>, actions: Array<{ __typename: 'PromotionActionObject', id: number, type: string, configuration: any, typeLabel?: string | null | undefined, description?: string | null | undefined }>, rules: Array<{ __typename: 'PromotionRuleObject', id: number, type: string, configuration: any, typeLabel?: string | null | undefined, description?: string | null | undefined }> }> };

export type PromotionItemQueryVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type PromotionItemQuery = { __typename: 'Query', promotion: { __typename: 'PromotionObject', id: number, name: string, code: string, description?: string | null | undefined, couponCode?: string | null | undefined, referrerCode?: string | null | undefined } };

export type PromotionItemsQueryVariables = Types.Exact<{
  page?: Types.InputMaybe<Types.PageInput>;
  filter?: Types.InputMaybe<Types.PromotionFilterInput>;
  sort?: Types.InputMaybe<Types.SortInput>;
}>;


export type PromotionItemsQuery = { __typename: 'Query', totalItemsCount: number, items: Array<{ __typename: 'PromotionObject', id: number, name: string, code: string, description?: string | null | undefined, couponCode?: string | null | undefined, referrerCode?: string | null | undefined }> };

export type PromotionDetailQueryVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type PromotionDetailQuery = { __typename: 'Query', promotion: { __typename: 'PromotionObject', id: number, name: string, code: string, adjustmentLabel?: string | null | undefined, description?: string | null | undefined, priority: number, exclusive: boolean, enabled: boolean, usageLimit?: number | null | undefined, customerUsageLimit?: number | null | undefined, startsAt?: any | null | undefined, endsAt?: any | null | undefined, couponCode?: string | null | undefined, referrerCode?: string | null | undefined, createdAt: any, updatedAt: any, archivedAt?: any | null | undefined, usage: number, channels: Array<{ __typename: 'ChannelObject', id: number, code: string, name: string, color: string }>, actions: Array<{ __typename: 'PromotionActionObject', id: number, type: string, configuration: any }>, rules: Array<{ __typename: 'PromotionRuleObject', id: number, type: string, configuration: any }> } };

export type PromotionFormQueryVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type PromotionFormQuery = { __typename: 'Query', promotion: { __typename: 'PromotionObject', id: number, name: string, code: string, adjustmentLabel?: string | null | undefined, description?: string | null | undefined, priority: number, exclusive: boolean, enabled: boolean, usageLimit?: number | null | undefined, customerUsageLimit?: number | null | undefined, startsAt?: any | null | undefined, endsAt?: any | null | undefined, couponCode?: string | null | undefined, couponType: Types.CouponType, couponBulkQty?: number | null | undefined, couponUsageLimit?: number | null | undefined, referrerCode?: string | null | undefined, channels: Array<{ __typename: 'ChannelObject', id: number, code: string, name: string, color: string }>, actions: Array<{ __typename: 'PromotionActionObject', id: number, type: string, configuration: any }>, rules: Array<{ __typename: 'PromotionRuleObject', id: number, type: string, configuration: any }> } };

export type PromotionRulesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type PromotionRulesQuery = { __typename: 'Query', promotionRules: Array<{ __typename: 'PromotionRuleTypeObject', id: string, label: string }> };

export type PromotionActionsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type PromotionActionsQuery = { __typename: 'Query', promotionActions: Array<{ __typename: 'PromotionActionTypeObject', id: string, label: string }> };

export type PromotionsCouponCodeSearchQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.PromotionFilterInput>;
}>;


export type PromotionsCouponCodeSearchQuery = { __typename: 'Query', promotions: Array<{ __typename: 'PromotionObject', couponCode?: string | null | undefined }> };

export type PromotionsReferrerCodeSearchQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.PromotionFilterInput>;
}>;


export type PromotionsReferrerCodeSearchQuery = { __typename: 'Query', promotions: Array<{ __typename: 'PromotionObject', referrerCode?: string | null | undefined }> };

export type CreateMutationVariables = Types.Exact<{
  data: Types.CreatePromotionInput;
}>;


export type CreateMutation = { __typename: 'Mutation', createPromotion: { __typename: 'PromotionObject', id: number, name: string, code: string, description?: string | null | undefined, couponCode?: string | null | undefined, referrerCode?: string | null | undefined } };

export type UpdateMutationVariables = Types.Exact<{
  data: Types.UpdatePromotionInput;
}>;


export type UpdateMutation = { __typename: 'Mutation', updatePromotion: { __typename: 'PromotionObject', id: number, name: string, code: string, description?: string | null | undefined, couponCode?: string | null | undefined, referrerCode?: string | null | undefined } };

export type UpdateEnabledMutationVariables = Types.Exact<{
  promotionId: Types.Scalars['Int']['input'];
  enabled: Types.Scalars['Boolean']['input'];
}>;


export type UpdateEnabledMutation = { __typename: 'Mutation', updatePromotionEnabled: { __typename: 'PromotionObject', id: number, name: string, code: string, description?: string | null | undefined, couponCode?: string | null | undefined, referrerCode?: string | null | undefined } };

export type CloneMutationVariables = Types.Exact<{
  data: Types.ClonePromotionInput;
}>;


export type CloneMutation = { __typename: 'Mutation', clonePromotion: { __typename: 'PromotionObject', id: number, name: string, code: string, description?: string | null | undefined, couponCode?: string | null | undefined, referrerCode?: string | null | undefined } };

export type ArchiveMutationVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type ArchiveMutation = { __typename: 'Mutation', archivePromotion: { __typename: 'PromotionObject', id: number, name: string, code: string, description?: string | null | undefined, couponCode?: string | null | undefined, referrerCode?: string | null | undefined } };

export type PromotionRuleDescriptionQueryVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
  configuration: Types.Scalars['JSON']['input'];
}>;


export type PromotionRuleDescriptionQuery = { __typename: 'Query', promotionRuleDescription?: string | null | undefined };

export type PromotionActionDescriptionQueryVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
  configuration: Types.Scalars['JSON']['input'];
}>;


export type PromotionActionDescriptionQuery = { __typename: 'Query', promotionActionDescription?: string | null | undefined };

export type PromotionGenerateBulkCouponCodesMutationVariables = Types.Exact<{
  promotionId: Types.Scalars['Int']['input'];
}>;


export type PromotionGenerateBulkCouponCodesMutation = { __typename: 'Mutation', generatePromotionBulkCouponCodes: Array<string> };

export const PromotionItemFragmentDoc = gql`
    fragment PromotionItem on PromotionObject {
  id
  name
  code
  description
  couponCode
  referrerCode
}
    `;
export const PromotionRowFragmentDoc = gql`
    fragment PromotionRow on PromotionObject {
  id
  name
  code
  adjustmentLabel
  description
  priority
  exclusive
  enabled
  usageLimit
  customerUsageLimit
  startsAt
  endsAt
  couponCode
  couponType
  couponBulkQty
  couponUsageLimit
  referrerCode
  createdAt
  updatedAt
  archivedAt
  usage
  channels {
    id
    code
    name
  }
  actions {
    id
    type
    configuration
    typeLabel
    description
  }
  rules {
    id
    type
    configuration
    typeLabel
    description
  }
}
    `;
export const PromotionFormFragmentDoc = gql`
    fragment PromotionForm on PromotionObject {
  id
  name
  code
  adjustmentLabel
  description
  priority
  exclusive
  enabled
  usageLimit
  customerUsageLimit
  startsAt
  endsAt
  couponCode
  couponType
  couponBulkQty
  couponUsageLimit
  referrerCode
  channels {
    ...ChannelItem
  }
  actions {
    id
    type
    configuration
  }
  rules {
    id
    type
    configuration
  }
}
    ${ChannelItemFragmentDoc}`;
export const PromotionDetailFragmentDoc = gql`
    fragment PromotionDetail on PromotionObject {
  id
  name
  code
  adjustmentLabel
  description
  priority
  exclusive
  enabled
  usageLimit
  customerUsageLimit
  startsAt
  endsAt
  couponCode
  referrerCode
  createdAt
  updatedAt
  archivedAt
  usage
  channels {
    ...ChannelItem
  }
  actions {
    id
    type
    configuration
  }
  rules {
    id
    type
    configuration
  }
}
    ${ChannelItemFragmentDoc}`;
export const PromotionRowDocument = gql`
    query PromotionRow($id: Int!) {
  promotion(id: $id) {
    ...PromotionRow
  }
}
    ${PromotionRowFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class PromotionRowGQL extends Apollo.Query<PromotionRowQuery, PromotionRowQueryVariables> {
    document = PromotionRowDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PromotionRowsDocument = gql`
    query PromotionRows($filter: PromotionFilterInput, $page: PageInput, $sort: SortInput) {
  items: promotions(page: $page, filter: $filter, sort: $sort) {
    ...PromotionRow
  }
  totalItemsCount: promotionsCount(filter: $filter)
}
    ${PromotionRowFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class PromotionRowsGQL extends Apollo.Query<PromotionRowsQuery, PromotionRowsQueryVariables> {
    document = PromotionRowsDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PromotionItemDocument = gql`
    query PromotionItem($id: Int!) {
  promotion(id: $id) {
    ...PromotionItem
  }
}
    ${PromotionItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class PromotionItemGQL extends Apollo.Query<PromotionItemQuery, PromotionItemQueryVariables> {
    document = PromotionItemDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PromotionItemsDocument = gql`
    query PromotionItems($page: PageInput, $filter: PromotionFilterInput, $sort: SortInput) {
  items: promotions(page: $page, filter: $filter, sort: $sort) {
    ...PromotionItem
  }
  totalItemsCount: promotionsCount(filter: $filter)
}
    ${PromotionItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class PromotionItemsGQL extends Apollo.Query<PromotionItemsQuery, PromotionItemsQueryVariables> {
    document = PromotionItemsDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PromotionDetailDocument = gql`
    query PromotionDetail($id: Int!) {
  promotion(id: $id) {
    ...PromotionDetail
  }
}
    ${PromotionDetailFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class PromotionDetailGQL extends Apollo.Query<PromotionDetailQuery, PromotionDetailQueryVariables> {
    document = PromotionDetailDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PromotionFormDocument = gql`
    query PromotionForm($id: Int!) {
  promotion(id: $id) {
    ...PromotionForm
  }
}
    ${PromotionFormFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class PromotionFormGQL extends Apollo.Query<PromotionFormQuery, PromotionFormQueryVariables> {
    document = PromotionFormDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PromotionRulesDocument = gql`
    query PromotionRules {
  promotionRules {
    id
    label
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PromotionRulesGQL extends Apollo.Query<PromotionRulesQuery, PromotionRulesQueryVariables> {
    document = PromotionRulesDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PromotionActionsDocument = gql`
    query PromotionActions {
  promotionActions {
    id
    label
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PromotionActionsGQL extends Apollo.Query<PromotionActionsQuery, PromotionActionsQueryVariables> {
    document = PromotionActionsDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PromotionsCouponCodeSearchDocument = gql`
    query PromotionsCouponCodeSearch($filter: PromotionFilterInput) {
  promotions(filter: $filter) {
    couponCode
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PromotionsCouponCodeSearchGQL extends Apollo.Query<PromotionsCouponCodeSearchQuery, PromotionsCouponCodeSearchQueryVariables> {
    document = PromotionsCouponCodeSearchDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PromotionsReferrerCodeSearchDocument = gql`
    query PromotionsReferrerCodeSearch($filter: PromotionFilterInput) {
  promotions(filter: $filter) {
    referrerCode
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PromotionsReferrerCodeSearchGQL extends Apollo.Query<PromotionsReferrerCodeSearchQuery, PromotionsReferrerCodeSearchQueryVariables> {
    document = PromotionsReferrerCodeSearchDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateDocument = gql`
    mutation Create($data: CreatePromotionInput!) {
  createPromotion(data: $data) {
    ...PromotionItem
  }
}
    ${PromotionItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateGQL extends Apollo.Mutation<CreateMutation, CreateMutationVariables> {
    document = CreateDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateDocument = gql`
    mutation Update($data: UpdatePromotionInput!) {
  updatePromotion(data: $data) {
    ...PromotionItem
  }
}
    ${PromotionItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateGQL extends Apollo.Mutation<UpdateMutation, UpdateMutationVariables> {
    document = UpdateDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateEnabledDocument = gql`
    mutation UpdateEnabled($promotionId: Int!, $enabled: Boolean!) {
  updatePromotionEnabled(promotionId: $promotionId, enabled: $enabled) {
    ...PromotionItem
  }
}
    ${PromotionItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateEnabledGQL extends Apollo.Mutation<UpdateEnabledMutation, UpdateEnabledMutationVariables> {
    document = UpdateEnabledDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CloneDocument = gql`
    mutation Clone($data: ClonePromotionInput!) {
  clonePromotion(data: $data) {
    ...PromotionItem
  }
}
    ${PromotionItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CloneGQL extends Apollo.Mutation<CloneMutation, CloneMutationVariables> {
    document = CloneDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ArchiveDocument = gql`
    mutation Archive($id: Int!) {
  archivePromotion(id: $id) {
    ...PromotionItem
  }
}
    ${PromotionItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ArchiveGQL extends Apollo.Mutation<ArchiveMutation, ArchiveMutationVariables> {
    document = ArchiveDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PromotionRuleDescriptionDocument = gql`
    query PromotionRuleDescription($id: String!, $configuration: JSON!) {
  promotionRuleDescription(ruleId: $id, configuration: $configuration)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PromotionRuleDescriptionGQL extends Apollo.Query<PromotionRuleDescriptionQuery, PromotionRuleDescriptionQueryVariables> {
    document = PromotionRuleDescriptionDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PromotionActionDescriptionDocument = gql`
    query PromotionActionDescription($id: String!, $configuration: JSON!) {
  promotionActionDescription(actionId: $id, configuration: $configuration)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PromotionActionDescriptionGQL extends Apollo.Query<PromotionActionDescriptionQuery, PromotionActionDescriptionQueryVariables> {
    document = PromotionActionDescriptionDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PromotionGenerateBulkCouponCodesDocument = gql`
    mutation PromotionGenerateBulkCouponCodes($promotionId: Int!) {
  generatePromotionBulkCouponCodes(promotionId: $promotionId)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PromotionGenerateBulkCouponCodesGQL extends Apollo.Mutation<PromotionGenerateBulkCouponCodesMutation, PromotionGenerateBulkCouponCodesMutationVariables> {
    document = PromotionGenerateBulkCouponCodesDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }