import { Injectable } from '@angular/core';
import { mapFetchResultData } from '@x/common/graph';
import {
  ArchiveUserAddressInput,
  CreateUserAddressInput,
  CreateUserInput,
  ResetUserPasswordInput,
  UpdateUserAddressInput,
  UpdateUserInput,
  UpdateUserRolesInput,
} from '@x/schemas/ecommerce';
import prompts from 'prompts';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  AnonymizeUserGQL,
  ArchiveUserAddressGQL,
  ArchiveUserGQL,
  CreateUserAddressGQL,
  CreateUserGQL,
  ResetUserPasswordGQL,
  SendPasswordResetEmailGQL,
  UpdateUserAddressGQL,
  UpdateUserGQL,
  UpdateUserRolesGQL,
  UserDetailGQL,
  UserItemGQL,
  UserItemsGQL,
  UserItemsQueryVariables,
  UserRolesGQL,
  UserRowsGQL,
  UserRowsQueryVariables,
  UserSearchGQL,
  UserSearchQueryVariables,
} from './gql/user.gql.generated';
import { IUserDetailObject, IUserItemObject, IUserRowObject } from './types/user';
import number = prompts.prompts.number;

@Injectable()
export class UserService {
  constructor(
    private userRowsGQL: UserRowsGQL,
    private userSearchGQL: UserSearchGQL,
    private userDetailGQL: UserDetailGQL,
    private userItemsGQL: UserItemsGQL,
    private userItemGQL: UserItemGQL,
    private updateUserAddressGQL: UpdateUserAddressGQL,
    private createUserAddressGQL: CreateUserAddressGQL,
    private archiveUserAddressGQL: ArchiveUserAddressGQL,
    private updateUserGQL: UpdateUserGQL,
    private createUserGQL: CreateUserGQL,
    private updateRolesGQL: UpdateUserRolesGQL,
    private archiveUserGQL: ArchiveUserGQL,
    private sendPasswordResetEmailGql: SendPasswordResetEmailGQL,
    private resetUserPasswordGql: ResetUserPasswordGQL,
    private userRolesGQL: UserRolesGQL,
    private anonymizeUserGQL: AnonymizeUserGQL,
  ) {}

  fetchRows(
    query: UserRowsQueryVariables,
  ): Observable<{ items: IUserRowObject[]; totalItemsCount: number }> {
    return this.userRowsGQL.fetch(query).pipe(mapFetchResultData((r) => r));
  }

  fetchUsers(query: UserSearchQueryVariables): Observable<IUserItemObject[]> {
    return this.userSearchGQL.fetch(query).pipe(mapFetchResultData((r) => r.users));
  }

  fetchItems(
    query: UserItemsQueryVariables,
  ): Observable<{ items: IUserItemObject[]; totalItemsCount: number }> {
    return this.userItemsGQL.fetch(query).pipe(mapFetchResultData((r) => r));
  }

  fetchItem(id: number): Observable<IUserItemObject> {
    return this.userItemGQL.fetch({ id }).pipe(mapFetchResultData((r) => r.user));
  }

  fetchDetail(id: number): Observable<IUserDetailObject> {
    return this.userDetailGQL.fetch({ id }).pipe(mapFetchResultData((r) => r.user));
  }

  fetchRoles(id: number) {
    return this.userRolesGQL.fetch({ id }).pipe(mapFetchResultData((r) => r.user.roles));
  }

  create(input: CreateUserInput) {
    return this.createUserGQL.mutate({ input }).pipe(map((r) => r.data?.createUser));
  }

  update(input: UpdateUserInput) {
    return this.updateUserGQL.mutate({ input }).pipe(map((r) => r.data?.updateUser));
  }

  createAddress(input: CreateUserAddressInput) {
    return this.createUserAddressGQL.mutate({ input }).pipe(map((r) => r.data?.createUserAddress));
  }

  updateAddress(input: UpdateUserAddressInput) {
    return this.updateUserAddressGQL.mutate({ input }).pipe(map((r) => r.data?.updateUserAddress));
  }

  archiveAddress(input: ArchiveUserAddressInput) {
    return this.archiveUserAddressGQL
      .mutate({ input })
      .pipe(map((r) => r.data?.archiveUserAddress));
  }

  updateRoles(input: UpdateUserRolesInput) {
    return this.updateRolesGQL.mutate({ input }).pipe(mapFetchResultData((r) => r.updateUserRoles));
  }

  archive(id: number) {
    return this.archiveUserGQL.mutate({ id }).pipe(map((r) => r.data?.archiveUser));
  }

  sendPasswordResetEmail(userId: number) {
    return this.sendPasswordResetEmailGql
      .mutate({ userId })
      .pipe(mapFetchResultData((r) => r.sendPasswordResetEmail));
  }

  resetUserPassword(input: ResetUserPasswordInput) {
    return this.resetUserPasswordGql.mutate({ input }).pipe(map((r) => r.data));
  }

  anonymizeUser(userId: number) {
    return this.anonymizeUserGQL.mutate({ userId }).pipe(map((r) => r.data));
  }
}
