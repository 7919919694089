<ng-container *ngIf="view">
  <div
    class="selection-overlay"
    *ngIf="view.selectionState === 'some' || view.selectionState === 'all'"
    @fadeInOutAnimation
  >
    <div class="selection-overlay-controls">
      <fa-icon
        *ngIf="_draggable"
        xDraggable
        [dragData]="{ view: view, selection: view.selected }"
        class="selection-overlay-drag-handle"
        icon="bars"
      ></fa-icon>

      <mat-checkbox
        [indeterminate]="view.selectionState === 'some'"
        [checked]="view.selectionState === 'all'"
        (change)="$event.checked ? view.selectAll() : view.clearSelection()"
        color="primary"
        class="selection-overlay-checkbox"
      >
        {{ view.selectionCount }} selected
        {{ view.isAnyItemLoading() ? ', ' + view.itemLoadingCount + ' items loading' : '' }}
      </mat-checkbox>
    </div>

    <div class="selection-overlay-actions">
      <ng-container *ngFor="let action of bulkActionTemplates; trackBy: trackActionById">
        <ng-container [ngTemplateOutlet]="action.template"></ng-container>
      </ng-container>
    </div>
  </div>
  <div class="table-container" (scroll)="onScroll($event)" #tableContainer>
    <table
      mat-table
      recycleRows
      [dataSource]="view"
      [trackBy]="trackById"
      matSort
      matSortStart="asc"
      [matSortDirection]="$any(view.sortOrder)"
      [matSortActive]="view.sortColumn"
      (matSortChange)="onSortChange($event)"
      (contentChanged)="onContentChange()"
      [class.x-dragging]="dragBoundary?.isDragging"
    >
      <ng-container *ngFor="let col of columnDefs" [matColumnDef]="col.id">
        <th
          mat-header-cell
          *matHeaderCellDef
          [mat-sort-header]="col.sortId ?? col.id"
          [disabled]="!col.sortable"
        >
          <ng-container *ngIf="col.title">
            {{ col.title }}
          </ng-container>
        </th>
        <ng-container *ngIf="col.droppable === true">
          <td
            mat-cell
            *matCellDef="let row"
            xDropZone
            [dropZoneData]="row"
            (zoneDrop)="onColumnDrop($event, col.id)"
          >
            <ng-container
              [ngTemplateOutlet]="col.template.template"
              [ngTemplateOutletContext]="{ $implicit: row }"
            ></ng-container>
          </td>
        </ng-container>
        <ng-container *ngIf="!col.droppable">
          <td mat-cell *matCellDef="let row">
            <ng-container
              [ngTemplateOutlet]="col.template.template"
              [ngTemplateOutletContext]="{ $implicit: row }"
            ></ng-container>
          </td>
        </ng-container>
      </ng-container>

      <!-- Action Column Definition -->
      <ng-container matColumnDef="_action" stickyEnd>
        <th mat-header-cell *matHeaderCellDef>Actions</th>
        <td mat-cell *matCellDef="let row" (click)="stopPropagation($event)">
          <div class="x-primary-row-actions">
            <ng-container
              *ngFor="let action of primaryRowActionTemplates; trackBy: trackActionById"
            >
              <ng-container
                [ngTemplateOutlet]="action.template"
                [ngTemplateOutletContext]="{ $implicit: row }"
              ></ng-container>
            </ng-container>
            <ng-container *ngIf="secondaryRowActionTemplates.length">
              <button
                x-data-button
                label="More Actions"
                icon="ellipsis-vertical"
                cdkOverlayOrigin
                #rowActionOverflowOverlayTrigger="cdkOverlayOrigin"
                (click)="onOverflowActionClick(row)"
              ></button>
              <ng-template
                #rowActionOverflowOverlay
                cdkConnectedOverlay
                [cdkConnectedOverlayOrigin]="rowActionOverflowOverlayTrigger"
                [cdkConnectedOverlayOpen]="rowActionOverlayOpenId === getId(row)"
                cdkConnectedOverlayBackdropClass="x-row-action-overflow-overlay-backdrop"
                cdkConnectedOverlayPanelClass="x-row-action-overflow-overlay-panel"
                [cdkConnectedOverlayHasBackdrop]="false"
                (overlayOutsideClick)="rowActionOverlayOpenId = null"
                [cdkConnectedOverlayPositions]="[
                  {
                    originX: 'end',
                    originY: 'bottom',
                    overlayX: 'end',
                    overlayY: 'top'
                  },
                  {
                    originX: 'end',
                    originY: 'top',
                    overlayX: 'end',
                    overlayY: 'bottom'
                  }
                ]"
              >
                <ng-container
                  *ngFor="let action of secondaryRowActionTemplates; trackBy: trackActionById"
                >
                  <ng-container
                    *ngIf="action.when(row)"
                    [ngTemplateOutlet]="action.template"
                    [ngTemplateOutletContext]="{ $implicit: row }"
                  ></ng-container>
                </ng-container>
              </ng-template>
            </ng-container>
          </div>
        </td>
      </ng-container>

      <!-- Selection Column Definition -->
      <ng-container matColumnDef="_select" sticky>
        <th mat-header-cell *matHeaderCellDef>
          <div class="selection-box">
            <mat-spinner
              diameter="40"
              strokeWidth="2"
              *ngIf="view.isAnyItemLoading()"
              @fadeInOutAnimation
            ></mat-spinner>
            <mat-checkbox
              #headCheckbox
              [indeterminate]="view.selectionState === 'some'"
              [checked]="view.selectionState === 'all'"
              (change)="$event.checked ? view.selectAll() : view.clearSelection()"
            ></mat-checkbox>
          </div>
        </th>
        <td mat-cell *matCellDef="let row" (click)="stopPropagation($event)">
          <div class="selection-box">
            <mat-checkbox
              #checkbox
              *ngIf="!view.isItemLoading(row); else loader"
              color="primary"
              [checked]="view.isSelected(row)"
              (change)="$event.checked ? view.select(row) : view.deselect(row)"
            ></mat-checkbox>
            <ng-template #loader>
              <mat-spinner diameter="40" strokeWidth="2"></mat-spinner>
            </ng-template>
          </div>
        </td>
      </ng-container>

      <!-- Reorder 'Column' Definition -->
      <ng-container matColumnDef="_reorderDropZone">
        <th
          mat-header-cell
          *matHeaderCellDef
          xDropZone
          dropZoneData="__header__"
          (zoneDrop)="onRowReorder($event)"
          [droppablePredicate]="canReorder"
        ></th>
        <td
          mat-cell
          *matCellDef="let row"
          xDropZone
          [dropZoneData]="row"
          (zoneDrop)="onRowReorder($event)"
          [droppablePredicate]="canReorder"
        ></td>
      </ng-container>

      <!-- Position Column Definition -->
      <ng-container matColumnDef="_position" sticky>
        <th mat-header-cell *matHeaderCellDef>Position</th>
        <td mat-cell *matCellDef="let row">
          <x-pill [color]="row[view.options.rowPositionField] ? 'primary' : 'accent'">
            {{ getRowPosition(row) ?? '-' }}
          </x-pill>
        </td>
      </ng-container>

      <!-- Drag Column Definition -->
      <ng-container matColumnDef="_drag" sticky>
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let row">
          @if (canDrag(row)) {
            <fa-icon icon="bars" class="drag-handle"></fa-icon>
          }
        </td>
      </ng-container>

      <!-- Row -->
      <tr
        mat-header-row
        *matHeaderRowDef="displayColumns; sticky: true"
        class="x-data-header-row"
      ></tr>
      <ng-container *ngIf="_draggable">
        <tr
          mat-row
          xDraggable
          *matRowDef="let row; columns: displayColumns; when: _draggable"
          [dragData]="row"
          [canDrag]="canDrag(row)"
          (click)="onRowClick($event, row)"
          class="x-data-row"
          [class.x-row-active]="isRowActive(row)"
          [ngClass]="rowClassFn?.(row) ?? ''"
        ></tr>
      </ng-container>
      <ng-container *ngIf="!_draggable">
        <tr
          mat-row
          *matRowDef="let row; columns: displayColumns"
          (click)="onRowClick($event, row)"
          [class.x-row-active]="isRowActive(row)"
          class="x-data-row"
          [ngClass]="rowClassFn?.(row) ?? ''"
        ></tr>
      </ng-container>
    </table>
  </div>

  <mat-menu #rowMenu="matMenu">
    <ng-template matMenuContent let-row></ng-template>
  </mat-menu>
</ng-container>
