import { Injectable } from '@angular/core';
import { IDataCollection, IDataCollectionProvider, IDataQuery } from '@x/common/data';
import { AgreementService, IAgreementRowObject } from '@x/ecommerce/domain-client';
import { Observable } from 'rxjs';
import { AgreementDeclarationService } from '../../domain-client/services/agreement-declarataion.service';
import { IAgreementDeclarationItemObject } from '../../domain-client/services/types/agreement-declaration';

@Injectable({ providedIn: 'root' })
export class AgreementCollectionProvider implements IDataCollectionProvider<IAgreementRowObject> {
  constructor(private agreementService: AgreementService) {}

  fetchCollection({
    searchText,
    page,
    sort,
    filter,
  }: Readonly<IDataQuery<any, any>>): Observable<IDataCollection<IAgreementRowObject>> {
    return this.agreementService.fetchRows({
      filter: { search: searchText, ...filter },
      page,
      sort,
    });
  }

  fetchSingle(id: string | number): IAgreementRowObject | Observable<IAgreementRowObject> {
    return this.agreementService.fetchRow(Number(id));
  }

  toId(model: IAgreementRowObject): string | number {
    return model.id;
  }

  toString(model: IAgreementRowObject): string {
    return model.name;
  }
}

@Injectable({ providedIn: 'root' })
export class AgreementDeclarationCollectionProvider
  implements IDataCollectionProvider<IAgreementDeclarationItemObject>
{
  constructor(private agreementDeclarationService: AgreementDeclarationService) {}

  fetchCollection({
    page,
    sort,
    filter,
  }: Readonly<IDataQuery<any, any>>): Observable<IDataCollection<IAgreementDeclarationItemObject>> {
    return this.agreementDeclarationService.fetchItems({
      filter,
      page,
      sort,
    });
  }

  toId(model: IAgreementDeclarationItemObject): number {
    return model.id;
  }

  toString(model: IAgreementDeclarationItemObject): string {
    return model.name;
  }
}
